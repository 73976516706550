/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 4px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.service-divider {
  flex-basis: 100%;
  height: 2px;
  background-color: #b6c2bf;
}
.visible-tablet {
  display: none !important;
}
@media (min-width: 991px) and (max-width: 1198px) {
  .visible-tablet {
    display: block !important;
  }
}
.service-col {
  flex-basis: 33%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border-bottom: 1px solid #e8e8e8;
}
.service-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 135px;
  flex-basis: 135px;
  background-color: #f5f5f5;
  padding: 4px 8px;
}
.service-col > .service-val {
  flex-grow: 1;
  padding: 4px 8px;
  min-width: 0;
}
.service-col > .service-val .service-val-row {
  margin-bottom: 2px;
}
.service-col > .service-val .service-val-row:last-child {
  margin-bottom: 0;
}
.service-col.col-span-2 {
  flex-basis: 66%;
}
@media (max-width: 990px) {
  .service-col {
    flex-basis: 100%;
  }
  .service-col.col-span-2 {
    flex-basis: 100%;
  }
}
@media (min-width: 991px) and (max-width: 1198px) {
  .service-col {
    flex-basis: 50%;
  }
  .service-col.col-span-2 {
    flex-basis: 50%;
  }
  .service-col.col-span-2-tablet {
    flex-basis: 100%;
  }
}
.service-field-service_no,
.service-field-claim_no {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  font-size: 16px;
}
.service-val-row.service-field-status {
  margin-bottom: 6px !important;
}
.service-val-row.service-field-status label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  display: inline-block;
  width: 75px;
}
.referrer-field-service {
  margin-left: 8px;
}
.service-ticket {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
