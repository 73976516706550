/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-progress-container {
  padding: 24px 24px 16px;
  background: #fff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  margin: 24px 0;
}
.mobile .service-progress-container {
  padding: 16px 0;
}
.tablet .service-progress-container {
  padding: 16px 0;
}
.service-progress-container.cancel {
  background-image: repeating-linear-gradient(45deg, #f5f5f5, #f5f5f5 20px, transparent 20px, transparent 35px);
}
@media (max-width: 575px) {
  .service-progress-container {
    display: none;
  }
}
.service-progress-cancel {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 16px;
  margin-bottom: 12px;
}
.service-progress-cancel .title {
  font-size: 1.5em;
  color: #f5222d;
}
.service-progress-cancel .description {
  font-size: 0.9em;
  color: #8c8c8c;
}
.service-progress-step::v-deep .ant-steps-item-title {
  font-size: 14px;
}
.service-progress-step::v-deep .ant-steps-item-description {
  font-size: 12px;
}
.tablet .service-progress-step::v-deep .ant-steps-item-title {
  font-size: 13px;
}
.tablet .service-progress-step::v-deep .ant-steps-item-content {
  width: 110px;
}
.tablet .service-progress-step::v-deep .ant-steps-item-tail {
  margin-left: 55px;
}
.tablet .service-progress-step::v-deep .ant-steps-item-icon {
  margin-left: 52px;
}
.mobile .service-progress-step::v-deep .ant-steps-item-title {
  font-size: 12px;
}
.mobile .service-progress-step::v-deep .ant-steps-item-description {
  display: none;
}
.mobile .service-progress-step::v-deep .ant-steps-item-content {
  width: 90px;
}
.mobile .service-progress-step::v-deep .ant-steps-item-tail {
  margin-left: 45px;
}
.mobile .service-progress-step::v-deep .ant-steps-item-icon {
  margin-left: 42px;
}
