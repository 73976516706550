/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-warning-container {
  border: 1px solid #ad8b00;
  border-radius: 4px;
  background-color: #feffe6;
  max-width: 800px;
  margin: 0 auto 24px;
  padding: 16px 36px;
}
.service-warning-container .service-warning-detail {
  margin-bottom: 12px;
}
.service-warning-container .service-warning-detail:last-child {
  margin-bottom: 0;
}
.service-warning-container .service-warning-detail label {
  display: block;
  color: #ad8b00;
  font-size: 1.1em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 4px;
}
.service-warning-container .service-warning-detail .description {
  font-size: 0.95em;
}
.mobile .service-warning-container {
  margin-top: 12px;
}
