/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-update-btn {
  float: left;
}
.mobile .service-update-btn {
  float: none;
  margin-top: 8px;
  text-align: left;
}
.mobile .service-tab-menu::v-deep {
  line-height: 36px;
}
.mobile .service-tab-menu::v-deep .ant-menu-item {
  padding: 0 12px;
  line-height: 36px;
  font-size: 13.5px;
}
