/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.delivery-detail {
  margin-top: 2px;
  margin-bottom: -8px;
  display: flex;
  flex-wrap: wrap;
}
.delivery-detail .delivery-col {
  margin: 0 16px 8px 0;
}
.delivery-detail .delivery-col label {
  color: #1890ff;
  margin-right: 2px;
}
.delivery-detail .delivery-col label::after {
  content: ' :';
}
.delivery-detail .delivery-value {
  color: #14837B;
  text-decoration: underline dashed;
}
